import React from "react";
import {UserAuth} from "../../contexts/auth-context";
import {RoutesEnum} from "../../enums/routes.enum";
import {Link, NavLink} from "react-router-dom";
import {FaSignOutAlt} from "react-icons/fa";

export const Header = () => {
    const {user, logout} = UserAuth();

    const navItemsAuthenticated = [
        {
            path: RoutesEnum.plants,
            name: 'Alle Pflanzen'
        },
        {
            path: RoutesEnum.plantAdd,
            name: 'Neue Pflanze'
        }
    ].map((item) => {
        return (
            <NavLink key={item.path} to={item.path}>{item.name}</NavLink>
        )
    })

    return (
        <header className="header">
            <div className="header__wrapper">
                <div className="header-container g-0 h-100">
                    <div className="row g-0 align-items-center text-center text-md-start h-100">
                        <div className="header__branding col-12 col-md">
                            <Link to='/'><strong>plantify</strong></Link>
                        </div>
                        <nav className="header__nav col-12 col-md-auto">
                            {
                                user &&
                                <>
                                    {navItemsAuthenticated}
                                  <span className="header__nav__item" onClick={logout}>
                                    <FaSignOutAlt/>
                                  </span>
                                </>
                            }
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}