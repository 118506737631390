import React from "react";
import {PlantForm} from "../../components/plants/plant-form";
import {UserAuth} from "../../contexts/auth-context";
import {faker} from "@faker-js/faker/locale/de";

export const PlantAddPage = () => {
    const randomName = faker.person.firstName('female');
    const {user} = UserAuth();

    return (
        <div className="section section--first">
            <div className="section__wrapper mvl-container">
                <h1>Neue Pflanze</h1>
                <PlantForm type="add" userId={user.uid} randomName={randomName}/>
            </div>
        </div>
    )
}