import React, {useState} from "react";
import {Link, Navigate, useLoaderData, useNavigate} from "react-router-dom";
import {PlantInterface} from "../../interface/plant.interface";
import {getEditPlantRoute} from "../../helpers/route-helper";
import {removePlantById} from "../../services/firestore.service";
import {RoutesEnum} from "../../enums/routes.enum";
import {dayjsToDisplayString} from "../../helpers/dayjs-to-string";
import Carousel from "nuka-carousel";
import {getImagePath} from "../../helpers/image-helper";
import {Toast, ToastMessageInterface} from "../../components/general/toast";
import {FaTrash} from "react-icons/fa";
import {AiFillEdit} from "react-icons/ai";
import {Image} from "../../components/general/image";
import {ImageVersionEnum} from "../../enums/image-version.enum";

export const PlantViewPage = () => {
    const plant = useLoaderData() as PlantInterface;
    const navigate = useNavigate();

    const [toast, setToast] = useState<ToastMessageInterface>();

    if (!plant)
        return <Navigate to={RoutesEnum.plants}/>

    const removeCurrentPlant = async () => {
        await removePlantById(plant.id);

        setToast({
            type: 'success',
            message: 'Pflanze erfolgreich gelöscht.'
        })

        navigate(RoutesEnum.plants)
    }

    return (
        <>
            {toast && <Toast toast={toast}/>}
            <div className="section section--hero hero">
                <div className="hero__gallery">
                    {plant.images.length > 1 ?
                        <Carousel autoplay={true} autoplayInterval={2000} cellAlign="left"
                                  slidesToShow={window.innerWidth > 992 ? 2 : 1} wrapAround={true}>
                            {plant.images.map((img) =>
                                <Image className="hero__img" key={img.url}
                                       src={getImagePath(img, ImageVersionEnum.small)} alt={plant.nickname}/>
                            )}
                        </Carousel>
                        :
                        <Image className="hero__img" src={getImagePath(plant.images[0], ImageVersionEnum.small)}
                               alt={plant.nickname}/>
                    }
                </div>

                <div className="hero__actions">
                    <Link className="hero__actions__item" to={getEditPlantRoute(plant.id)}>
                        <AiFillEdit/>
                    </Link>
                    <span className="hero__actions__item" onClick={removeCurrentPlant}>
                        <FaTrash/>
                    </span>
                </div>
            </div>
            <div className="section mvl-container">
                <h1 className="mb-4">{plant.nickname ? plant.nickname : 'plant'} {plant.botanicalName ? `(${plant.botanicalName})` : ''}</h1>

                <hr className="mt-4 mb-3"/>

                <div className="row gy-3 mt-4">
                    <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                        <p>
                            <strong>Hinzugefügt am</strong>
                        </p>
                        <p>
                            {dayjsToDisplayString(plant.createdAt)}
                        </p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                        <p>
                            <strong>Bearbeitet am</strong>
                        </p>
                        <p>
                            {dayjsToDisplayString(plant.updatedAt)}
                        </p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                        <p>
                            <strong>Zuletzt gegossen</strong>
                        </p>
                        <p>
                            {dayjsToDisplayString(plant.lastTimeWatered)}
                        </p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                        <p>
                            <strong>Zuletzt gedüngt</strong>
                        </p>
                        <p>
                            {dayjsToDisplayString(plant.lastTimeFertilized)}
                        </p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                        <p>
                            <strong>Zuletzt umgetopft</strong>
                        </p>
                        <p>
                            {dayjsToDisplayString(plant.lastTimeRepotted)}
                        </p>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                        <p>
                            <strong>Aktuelle Topfgröße</strong>
                        </p>
                        <p>
                            {plant.currentPotRadius ? (plant.currentPotRadius + ' cm') : '/'}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}