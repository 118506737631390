import {UserAuth} from "../../contexts/auth-context";
import React from "react";

export const LoginPage = () => {
    const {login} = UserAuth();

    return (
        <div className="section section--first">
            <div className="mvl-container">
                <h1>Wilkommen bei Plantify</h1>
                <button className="btn btn--primary w-100" onClick={login}>Mit Google fortfahren</button>
            </div>
        </div>
    )
}
