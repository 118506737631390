import React from "react";
import {PlantForm} from "../../components/plants/plant-form";
import {Navigate, useLoaderData} from "react-router-dom";
import {PlantInterface} from "../../interface/plant.interface";
import {RoutesEnum} from "../../enums/routes.enum";
import {UserAuth} from "../../contexts/auth-context";

export const PlantEditPage = () => {
    const plant = useLoaderData() as PlantInterface;
    const {user} = UserAuth();

    if (!plant)
        return <Navigate to={RoutesEnum.plants}/>

    return (
        <div className="section section--first">
            <div className="section__wrapper mvl-container">
                <h1>Pflanze
                    "{plant.nickname ? plant.nickname : 'plant'}{plant.botanicalName ? ` (${plant.botanicalName})` : ''}"
                    Bearbeiten</h1>
                <PlantForm type="edit" plant={plant} userId={user.uid}/>
            </div>
        </div>
    )
}
