import React from "react";
import {PlantImageInterace, PlantInterface} from "../../interface/plant.interface";
import {dayjsToInputString} from "../../helpers/dayjs-to-string";
import {addPlant, updatePlantById} from "../../services/firestore.service";
import {getEditPlantRoute, getViewPlantRoute} from "../../helpers/route-helper";
import {Navigate} from "react-router-dom";
import {FileUpload} from "./file-upload";
import {Toast, ToastMessageInterface} from "../general/toast";

type PlantFormProps = {
    userId: string;
    type: 'add' | 'edit';
    plant?: PlantInterface;
    randomName?: string;
}

type PlantFormState = {
    loading: boolean;
    redirect: string;
    toast: ToastMessageInterface;
    plant: PlantInterface;
}

export class PlantForm extends React.Component<PlantFormProps, PlantFormState> {

    private readonly fileUploadRef: any;

    constructor(props) {
        super(props);

        this.fileUploadRef = React.createRef();

        this.state = {
            loading: false,
            redirect: '',
            toast: null,
            plant: {
                id: '',
                nickname: '',
                botanicalName: '',
                images: [],
                lastTimeFertilized: '',
                lastTimeWatered: '',
                lastTimeRepotted: '',
                currentPotRadius: '',
            }
        };

        const {plant} = this.props;

        // Merge given data from props if exist
        if (this.props.type === 'edit' && plant) {
            this.state = {
                ...this.state,
                plant: {
                    ...this.state.plant,
                    ...{
                        ...plant,
                        ...{
                            // transform date into the string a input field needs to initialise
                            lastTimeFertilized: plant.lastTimeFertilized ? dayjsToInputString(plant.lastTimeFertilized) : undefined,
                            lastTimeWatered: plant.lastTimeWatered ? dayjsToInputString(plant.lastTimeWatered) : undefined,
                            lastTimeRepotted: plant.lastTimeRepotted ? dayjsToInputString(plant.lastTimeRepotted) : undefined
                        }
                    }
                }
            } as any;
        }
    }

    submit(e?, images?) {
        if (e)
            e.preventDefault();

        this.setState({
            loading: true
        });

        images = images ? images : this.fileUploadRef.current.state.imageFiles;

        if (images.length !== 0 && this.props.type === 'add') {
            // Add new Plant only with image data

            addPlant({images, nickname: this.props.randomName}, this.props.userId).then((id) => {
                this.setState({
                    loading: false,
                    toast: {
                        type: 'success',
                        message: 'Pflanze erfolgreich hinzugefügt.'
                    },
                    redirect: getEditPlantRoute(id)
                });
            });

        } else if (images.length !== 0 && this.props.type === 'edit') {
            // Update plant and merge image data

            const plant = {...this.state.plant, ...{images: this.state.plant.images.concat(images)}}

            updatePlantById(this.state.plant.id, plant).then((id) => {
                this.setState({
                    loading: false,
                    toast: {
                        type: 'success',
                        message: 'Pflanze erfolgreich bearbeitet.'
                    },
                    redirect: getViewPlantRoute(this.state.plant.id),
                });
            })
        } else {
            // Update without images

            updatePlantById(this.state.plant.id, this.state.plant).then((res) => {
                this.setState({
                    loading: false,
                    toast: {
                        type: 'success',
                        message: 'Pflanze erfolgreich bearbeitet.'
                    },
                    redirect: getViewPlantRoute(this.state.plant.id)
                });
            })
        }
    }

    onTitleChange(event) {
        this.setState({
            plant: {
                ...this.state.plant, nickname: event.currentTarget.value
            }
        });
    }

    onBotanicalNameChange(event) {
        this.setState({
            plant: {
                ...this.state.plant, botanicalName: event.currentTarget.value
            }
        });
    }

    onLastTimeFertilizedChange(event) {
        this.setState({
            plant: {
                ...this.state.plant, lastTimeFertilized: event.currentTarget.value
            }
        });
    }

    onLastTimeWateredChange(event) {
        this.setState({
            plant: {
                ...this.state.plant, lastTimeWatered: event.currentTarget.value
            }
        });
    }

    onLastTimeRepottedChange(event) {
        this.setState({
            plant: {
                ...this.state.plant, lastTimeRepotted: event.currentTarget.value
            }
        });
    }

    onCurrentPotRadiusChange(event) {
        console.log(event)

        this.setState({
            plant: {
                ...this.state.plant, currentPotRadius: event.currentTarget.value
            }
        });
    }

    onImageRemove(image: PlantImageInterace) {
        const imagesReduced = this.state.plant.images.filter((img) => img !== image);

        this.setState({
            plant: {
                ...this.state.plant,
                ...{images: imagesReduced}
            }
        });
    }

    render() {
        const {plant} = this.state;

        return (
            <>
                {this.state.toast && <Toast toast={this.state.toast}/>}

                {this.state.redirect && <Navigate to={this.state.redirect}/>}

                <form onSubmit={this.submit.bind(this)} className={`form${this.state.loading ? ' form--loading' : ''}`}>
                    <div className="form__wrapper row gy-4">
                        <FileUpload ref={this.fileUploadRef}
                                    fileTypes={['JPG', 'PNG']}
                                    uploadedFiles={plant.images}
                                    submit={this.submit.bind(this)}
                                    onImageRemove={this.onImageRemove.bind(this)}
                                    text="<strong>Wähle ein Bild deiner Pflanze aus</strong> oder ziehe es hier her."
                        />
                        {
                            this.props.type === 'edit' &&
                            <>
                                <div className="col-12">
                                    <hr className="my-3"/>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="nickname">Spitzname</label>
                                        <input id="nickname" name="nickname" type="text"
                                               value={plant.nickname}
                                               onChange={this.onTitleChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="botanicalName">Botanischer Name</label>
                                        <input id="botanicalName" name="botanicalName" type="text"
                                               value={plant.botanicalName}
                                               onChange={this.onBotanicalNameChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastTimeFertilized">
                                            Zuletzt gedüngt
                                        </label>
                                        <input id="lastTimeFertilized" name="lastTimeFertilized" type="date"
                                               value={plant.lastTimeFertilized}
                                               onChange={this.onLastTimeFertilizedChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastTimeWatered">Zuletzt gegossen</label>
                                        <input id="lastTimeWatered" name="lastTimeWatered" type="date"
                                               value={plant.lastTimeWatered}
                                               onChange={this.onLastTimeWateredChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <hr className="my-3"/>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lastTimeRepotted">Zuletzt umgetopft</label>
                                        <input id="lastTimeRepotted" name="lastTimeRepotted" type="date"
                                               value={plant.lastTimeRepotted}
                                               onChange={this.onLastTimeRepottedChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="currentPotRadius">Aktuelle Topfgröße</label>
                                        <input id="currentPotRadius" name="currentPotRadius" type="number"
                                               value={plant.currentPotRadius}
                                               onChange={this.onCurrentPotRadiusChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            </>
                        }

                        <div className="col-12 mt-5">
                            <button disabled={this.props.type === 'add'} className="btn btn--primary w-100"
                                    type="submit">
                                {this.props.type === 'add' ? ' Neue Pflanze' : ' Änderungen'} speichern
                            </button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}