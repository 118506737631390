import React from 'react';
import {Navigate} from 'react-router-dom';
import {UserAuth} from '../../contexts/auth-context';
import {RoutesEnum} from "../../enums/routes.enum";

const LoginRoute = ({children}) => {
    const {user} = UserAuth();

    if (user) {
        return <Navigate to={RoutesEnum.plants} replace={true}/>;
    }

    return children;
};

export default LoginRoute;