import React from "react";
import {FileUploader} from "react-drag-drop-files";
import {dayjsToDisplayString} from "../../helpers/dayjs-to-string";
import dayjs from "dayjs";
import {PlantImageInterace} from "../../interface/plant.interface";
import {uploadFiles} from "../../services/firestorage.service";
import {getImagePath} from "../../helpers/image-helper";
import {FaTrash} from "react-icons/fa";
import {Image} from "../general/image";
import {ImageVersionEnum} from "../../enums/image-version.enum";

type FileUploadProgressInterface = {
    title: string;
    value: number;
}

type FileUploadProps = {
    fileTypes: Array<string>;
    uploadedFiles: Array<PlantImageInterace>;
    submit: any;
    onImageRemove: any;
    text: string;
}

type FileUploadState = {
    loading: boolean,
    progresses: Array<any>;
    imageFiles: Array<any>;
    redirect: '',
}

export class FileUpload extends React.Component<FileUploadProps, FileUploadState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            progresses: [],
            imageFiles: [],
            redirect: '',
        }
    }

    submit(e?, files?) {
        if (e)
            e.preventDefault();

        this.setState({
            loading: true
        });

        files = files ? files : this.state.imageFiles;

        if (files.length !== 0) {
            uploadFiles(files, this.onFileUploadProgress.bind(this)).then((images) => {
                this.props.submit(undefined, images)
            });
        }
    }

    onFileUploadProgress(progress: FileUploadProgressInterface) {
        const progressItemExist = this.state.progresses.find((prg) => prg.title === progress.title)

        if (progressItemExist) {
            const progresses = this.state.progresses.map((prgs) => prgs.title === progress.title ? progress : prgs)

            this.setState({
                progresses: progresses
            });
        } else {
            this.setState({
                progresses: [
                    ...this.state.progresses,
                    ...[progress]
                ]
            });
        }
    }

    onImageChange(fileList) {
        const files = [];

        if (fileList) {
            [...fileList].map(file => files.push(file));
        }

        this.submit(undefined, files);
    }

    onImageRemove(imageToRemove: PlantImageInterace) {
        this.props.onImageRemove(imageToRemove);
    }

    render() {
        const {fileTypes, uploadedFiles} = this.props;

        const dropzoneTemplate = <div className="dropzone">
            {
                this.state.imageFiles.length !== 0 ?
                    (
                        <p className="mb-2">Current files: <strong>{
                            this.state.imageFiles.map((file, i) =>
                                <span key={file.name}>{`${file.name}${this.state.imageFiles.length - 1 === i ? '' : ', '}`}</span>)
                        }</strong></p>
                    ) : (
                        <p className="mb-2" dangerouslySetInnerHTML={{__html: this.props.text}}></p>
                    )
            }
            <small className="text-muted">
                <span>Erlaubte Dateiendungen: </span>
                <span>{fileTypes.join(', ')}.</span>
            </small>
        </div>

        return (
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="images" hidden>Bilder der Pflanze</label>
                    <FileUploader multiple={true} children={dropzoneTemplate} handleChange={this.onImageChange.bind(this)} id="images" name="images" types={fileTypes}/>
                    {
                        this.state.progresses.length >= 1 &&
                        <>
                          <hr className="my-5"/>
                            {
                                this.state.progresses.map((progress, i) =>
                                    <div key={i}>
                                        <div className="row align-items-center image-preview mt-4">
                                            <div className="col-auto">
                                                <span>{progress.title}</span>
                                            </div>
                                            <div className="col">
                                                <progress className="w-100" max="100" value={progress.value}></progress>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    }
                    {
                        uploadedFiles && uploadedFiles.length >= 1 &&
                        <>
                          <hr className="my-5"/>
                            {
                                uploadedFiles.map((file, i) =>
                                    <div key={i}>

                                        <div className="row align-items-center image-preview mt-4">
                                            <div className="col-auto">
                                                <Image src={getImagePath(file, ImageVersionEnum.small)} alt=""/>
                                            </div>
                                            <div className="col">
                                                <span>{file.originalFileName}</span><br/>
                                                <small className="text-muted">({dayjsToDisplayString(dayjs(file.createdAt))})</small>
                                            </div>
                                            <div className="col-auto text-end">
                                                    <span className="link" onClick={() => this.onImageRemove(file)}>
                                                        <FaTrash/>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    }
                </div>
            </div>
        )
    }
}