import {createContext, useContext, useEffect, useState} from 'react';
import {onAuthStateChanged, signInWithPopup, signOut,} from 'firebase/auth';
import {auth, provider} from '../utils/firebase-config';
import {RoutesEnum} from "../enums/routes.enum";
import {useNavigate} from "react-router-dom";

const UserContext = createContext(null);

export const AuthContextProvider = ({children}) => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});

    const login = async (redirectUrl: string | RoutesEnum) => {
        await signInWithPopup(auth, provider);
        
        if (redirectUrl) {
            navigate(redirectUrl);
        }
    }

    const logout = () => {
        return signOut(auth)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <UserContext.Provider value={{user, logout, login}}>
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext);
};