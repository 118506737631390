import {isRouteErrorResponse, useRouteError} from "react-router-dom";

export const ErrorPage = () => {
    const error = useRouteError();

    let message;

    if (isRouteErrorResponse(error)) {
        message = <i>{error.statusText}</i>;
    } else if (error instanceof Error) {
        message = <i>{error.message}</i>
    }

    return (
        <div className="section section--first">
            <div className="mvl-container">
                <h1 className="mb-3">Ooops... Da hat etwas nicht geklappt.</h1>
                {
                    message && (
                        <>
                            <p className="text-muted">{message}</p>
                            <p>Bitte versuche es später erneut.</p>
                        </>
                    )
                }
            </div>
        </div>
    )
}