import {NavLink} from "react-router-dom";

export const NotFoundPage = () => {
    return (
        <div className="section section--first">
            <div className="mvl-container">
                <h1 className="mb-3">Die Seite konnte nicht gefunden werden</h1>
                <p>Wechsel zurück zur <NavLink className="link link--visible" to="/">Startseite</NavLink>.</p>
            </div>
        </div>
    )
}