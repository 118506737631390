import React from 'react';

import './styles/app.scss';

import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import {RoutesEnum} from "./enums/routes.enum";
import ProtectedRoute from "./components/utils/protected-route";
import {AuthContextProvider} from "./contexts/auth-context";
import LoginRoute from "./components/utils/login-route";
import {RootLayout} from "./layouts/root-layout";
import {LoginPage} from "./views/auth/login";
import {PlantsPage} from "./views/plants/plants";
import {PlantAddPage} from "./views/plants/plant-add";
import {PlantEditPage} from "./views/plants/plant-edit";
import {PlantViewPage} from "./views/plants/plant-view";
import {NotFoundPage} from "./views/utils/not-found";
import {getAllPlants, getPlantById} from "./services/firestore.service";
import {ImprintPage} from "./views/legal/imprint";
import {ErrorPage} from "./views/utils/error";

const router = createBrowserRouter([
    {
        path: '',
        element: <AuthContextProvider><RootLayout/></AuthContextProvider>,
        children: [
            {
                path: '/',
                element: <LoginRoute><LoginPage/></LoginRoute>,
                errorElement: <ErrorPage/>,
            },
            {
                path: RoutesEnum.imprint,
                element: <ImprintPage/>,
                errorElement: <ErrorPage/>,
            },
            {
                path: RoutesEnum.plants,
                element: <ProtectedRoute><Outlet/></ProtectedRoute>,
                errorElement: <ErrorPage/>,
                children: [
                    {
                        path: '',
                        element: <PlantsPage/>,
                        loader: async () => {
                            return await getAllPlants();
                        }
                    },
                    {
                        path: RoutesEnum.plantAdd,
                        element: <PlantAddPage/>
                    },
                    {
                        path: RoutesEnum.plantEdit,
                        element: <PlantEditPage/>,
                        loader: async (e: { params: any }) => {
                            return await getPlantById(e.params.id);
                        }
                    },
                    {
                        path: RoutesEnum.plantView,
                        element: <PlantViewPage/>,
                        loader: async (e: { params: any }) => {
                            return await getPlantById(e.params.id);
                        }
                    }
                ]
            },
            {
                path: '*',
                element: <NotFoundPage/>
            }
        ]
    }
]);

const App = () => {
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
