import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc} from "firebase/firestore";
import {db} from "../utils/firebase-config";
import {FirestoreCollectionsEnum} from "../enums/firestore-collections.enum";
import {PlantInterface} from "../interface/plant.interface";
import dayjs from "dayjs";

export const getAllPlants = async () => {
    const documentRefs = getDocs(collection(db, FirestoreCollectionsEnum.plants))

    return await documentRefs.then((querySnapshot) => {
        return querySnapshot.docs
            .map((querySnapshot) => {
                let plant = querySnapshot.data() as PlantInterface;

                plant = transformPlantFromFirestore(plant);

                return {...plant, id: querySnapshot.id} as PlantInterface
            });
    })
}

export const getPlantById = async (id: string) => {
    const documentRef = getDoc(doc(db, FirestoreCollectionsEnum.plants, id))

    return await documentRef.then((querySnapshot) => {
        if (!querySnapshot.exists()) {
            return null;
        }

        let plant = querySnapshot.data() as PlantInterface;

        // Transform firestore timestamp to dayjs object
        plant = transformPlantFromFirestore(plant);


        return plant ? {...plant, id: querySnapshot.id} as PlantInterface : null;
    })
}

export const addPlant = async (plant: PlantInterface, userId: string) => {
    plant = {
        ...plant,
        ...{
            userId,
            createdAt: dayjs().toDate(),
            updatedAt: dayjs().toDate()
        }
    }

    plant = transformPlantToFirestore(plant);

    const documentRef = addDoc(collection(db, FirestoreCollectionsEnum.plants), plant)

    return await documentRef.then((querySnapshot) => {
        return querySnapshot.id
    })
}

export const updatePlantById = async (id: string, plant: PlantInterface) => {
    plant = {
        ...plant,
        ...{
            updatedAt: dayjs().toDate()
        }
    }

    plant = transformPlantToFirestore(plant);

    const documentRef = updateDoc(doc(db, FirestoreCollectionsEnum.plants, id), plant as any)

    return await documentRef;
}

export const removePlantById = async (id: string) => {
    const documentRef = deleteDoc(doc(db, FirestoreCollectionsEnum.plants, id))

    return await documentRef;
}

// Transform string value from input fields to js date object
const transformPlantToFirestore = (plant: PlantInterface) => {
    if (plant) {
        plant.lastTimeFertilized = plant.lastTimeFertilized ? dayjs(plant.lastTimeFertilized).toDate() : undefined;
        plant.lastTimeWatered = plant.lastTimeWatered ? dayjs(plant.lastTimeWatered).toDate() : undefined;
        plant.lastTimeRepotted = plant.lastTimeRepotted ? dayjs(plant.lastTimeRepotted).toDate() : undefined;
    }

    if (plant.createdAt) {
        plant.createdAt = plant.createdAt ? dayjs(plant.createdAt).toDate() : undefined;
    }

    if (plant.updatedAt) {
        plant.updatedAt = plant.updatedAt ? dayjs(plant.updatedAt).toDate() : undefined;
    }

    if (plant.images) {
        plant.images = plant.images.map(plant => plant.createdAt ? {...plant, ...{createdAt: dayjs(plant.createdAt).toDate()}} : plant)
    }

    return plant;
}

// Transform firestore timestamp to dayjs object
const transformPlantFromFirestore = (plant: PlantInterface) => {
    if (plant) {
        plant.lastTimeFertilized = plant.lastTimeFertilized ? dayjs.unix(plant.lastTimeFertilized.seconds) : undefined;
        plant.lastTimeWatered = plant.lastTimeWatered ? dayjs.unix(plant.lastTimeWatered.seconds) : undefined;
        plant.lastTimeRepotted = plant.lastTimeRepotted ? dayjs.unix(plant.lastTimeRepotted.seconds) : undefined;
    }

    if (plant.createdAt) {
        plant.createdAt = plant.createdAt ? dayjs.unix(plant.createdAt.seconds) : undefined;
    }

    if (plant.updatedAt) {
        plant.updatedAt = plant.updatedAt ? dayjs.unix(plant.updatedAt.seconds) : undefined;
    }

    if (plant.images) {
        plant.images = plant.images.map(plant => plant.createdAt ? {...plant, ...{createdAt: dayjs.unix(plant.createdAt.seconds)}} : plant)
    }

    return plant;
}