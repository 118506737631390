import React from "react";
import {NavLink} from "react-router-dom";
import {RoutesEnum} from "../../enums/routes.enum";

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="mvl-container">
                <div className="row">
                    <nav className="footer__nav col-auto">
                        <NavLink className="footer__nav__item" to={RoutesEnum.imprint}>Impressum</NavLink>
                    </nav>
                    <div className="col text-end">
                        <span>© {new Date().getFullYear()} </span><a href="https://mvlanga.com" target="_blank" rel="noreferrer">
                        Moriz von Langa
                    </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}