import {Footer} from "../components/general/footer";
import {Outlet} from "react-router-dom";
import React from "react";
import {Header} from "../components/general/header";

export const RootLayout = () => {
    return (
        <>
            <div className="root-layout">
                <Header/>

                <main className="main">
                    <Outlet/>
                </main>

                <Footer/>
            </div>
        </>
    )
}