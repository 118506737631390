export const ImprintPage = () => {
    return (
        <div className="section section--first">
            <div className="mvl-container">
                <h1>Impressum</h1>
                <p>
                    Moriz von Langa<br/>
                    Petzoltstr. 6<br/>
                    90443 Nürnberg
                    <br/><br/>
                    Email: morizvlanga[at]gmail.com<br/>
                    Telefon: 015751212916<br/>
                    Website: <a href="https://mvlanga.com" target="_blank" rel="noreferrer">mvlanga.com</a>
                    <br/><br/>
                    Vertreten durch:<br/>
                    Moriz von Langa
                    <br/><br/>
                    Umsatzsteuer-ID nach §27a Umsatzsteuergesetz:<br/>
                    216/242/30150
                </p>
            </div>
        </div>
    )
}