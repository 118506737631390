import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../utils/firebase-config";
import dayjs from "dayjs";
import {PlantImageInterace} from "../interface/plant.interface";
import {v4 as uuidv4} from 'uuid';

export const uploadFiles = async (files, onProgress) => {
    return new Promise(async (resolve, reject) => {
        const images = await Promise.all(
            files.map(async (file) => await uploadFile(file, onProgress))
        );

        resolve(images);
    })
}

export const uploadFile = (file, onProgress) => {
    return new Promise((resolve, reject) => {
        const id = uuidv4();

        const fileName = `plant_${id}.${file.type.replace(/(.*)\//g, '')}`;

        const storageRef = ref(storage, `plants/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                onProgress({
                    title: file.name,
                    value: Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                });
            },
            (error) => {
                reject(error);
            },
            async () => {
                const url = await getDownloadURL(uploadTask.snapshot.ref);
                resolve({
                    id,
                    url,
                    createdAt: dayjs().toDate(),
                    fileName: fileName,
                    originalFileName: file.name
                } as PlantImageInterace);
            }
        );
    })
}