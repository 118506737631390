import React, {useState} from "react";
import {PlantInterface} from "../../interface/plant.interface";
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import {getEditPlantRoute, getViewPlantRoute} from "../../helpers/route-helper";
import {removePlantById} from "../../services/firestore.service";
import {RoutesEnum} from "../../enums/routes.enum";
import {getImagePath} from "../../helpers/image-helper";
import {ImageVersionEnum} from "../../enums/image-version.enum";
import {Toast, ToastMessageInterface} from "../../components/general/toast";
import {FaEdit, FaEye, FaTrash} from "react-icons/fa";
import {Image} from "../../components/general/image";

export const PlantsPage = () => {
    const navigate = useNavigate();
    const plants = useLoaderData() as Array<PlantInterface>;

    const [toast, setToast] = useState<ToastMessageInterface>();

    const removePlant = async (id: string) => {
        await removePlantById(id);

        setToast({
            type: 'success',
            message: 'Pflanze erfolgreich gelöscht.'
        })

        navigate(RoutesEnum.plants);
    }

    return (
        <>
            {toast && <Toast toast={toast}/>}
            <div className="section section--first">
                <div className="section__wrapper mvl-container">
                    <h1>Dein Garten 🌱</h1>
                    <div className="row g-4">
                        {plants.length > 0 && plants?.map((plant, i) => (
                            <div className="col-12 col-md-6 col-lg-4" key={i}>
                                <div className="card">
                                    <div className="row align-items-center">
                                        <div className="col-12 card__img">
                                            <Link to={getViewPlantRoute(plant.id)}>
                                                <Image src={getImagePath(plant.images[0], ImageVersionEnum.small)} alt={plant.nickname}/>
                                            </Link>
                                        </div>
                                        <div className="col-12">
                                            <div className="card__content">
                                                <div className="row">
                                                    <div className="col">
                                                        <Link to={getViewPlantRoute(plant.id)}>
                                                            {plant.nickname ? plant.nickname : 'plant'} {plant.botanicalName ? `(${plant.botanicalName})` : ''}
                                                        </Link>
                                                    </div>
                                                    <div className="col-auto card__actions">
                                                        <Link className="card__actions__item" to={getViewPlantRoute(plant.id)}>
                                                            <FaEye/>
                                                        </Link>
                                                        <Link className="card__actions__item" to={getEditPlantRoute(plant.id)}>
                                                            <FaEdit/>
                                                        </Link>
                                                        <span className="card__actions__item" onClick={() => removePlant(plant.id)}>
                                                            <FaTrash/>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        }

                        {plants.length <= 0 && <div className="col-12">
                          Es scheint so als hättest du noch keine
                          Pflanzen. <Link className="link link--visible" to={RoutesEnum.plantAdd}>Füge
                          jetzt eine hinzu</Link>.
                        </div>}
                    </div>
                    <p className="text-muted d-block mt-5">Info: Eine Trennung zwischen Usern und Pflanzen
                        existiert noch nicht, aktuell kann jeder User jede Pflanze bearbeiten.</p>
                </div>
            </div>
        </>
    )
}