import {ReactNode, useEffect, useRef} from "react";
import {createPortal} from "react-dom";

type ToastInterface = {
    children?: ReactNode,
    toast?: ToastMessageInterface
}

export interface ToastMessageInterface {
    type: 'success' | 'error' | 'warning' | 'info';
    message: string;
    duration?: number;
}

export const Toast = ({children, toast}: ToastInterface) => {
    // Added portal root to prevent z-index issue in the future
    const portalRoot = document.querySelector("#toast-root") as HTMLElement;

    // Added ref to check if toast got already inserted, this is necessary because sometimes react hooks run multiple times
    const toastInsertedRef = useRef(false);
    const defaultDuration = 6000;

    let toastInner = document.createElement('div');

    if (toast) {
        toastInner.classList.add('toast', `toast--${toast.type}`);
        toastInner.innerText = decodeURI(toast.message)
    }

    useEffect(() => {
        if (toastInsertedRef.current) return;

        toastInsertedRef.current = true;

        portalRoot.appendChild(toastInner);

        setTimeout(() => {
            toastInner.classList.add('visible');
        }, 100)

        setTimeout(() => {
            toastInner.classList.remove('visible');
        }, toast?.duration ? toast.duration - 1000 : defaultDuration - 1000)

        setTimeout(() => {
            toastInner.remove();
        }, toast?.duration ? toast.duration : 6000);
    });

    return createPortal(children, portalRoot);
}