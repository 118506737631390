import {useState} from "react";

/*
Firebase storage takes a few seconds to convert the uploaded image to a compressed version with a different url, therefore we can try to reload the image on error after x seconds
 */
export const Image = ({src, alt, ...props}) => {

    const [currentSrc, setSrc] = useState(src);

    const useFallbackSrc = () => {
        setTimeout(() => {
            setSrc(`${currentSrc}?rand=${Math.random()}`)
        }, 1000)
    }

    return (
        <img src={currentSrc} onError={useFallbackSrc} alt={alt} className={props?.className}/>
    )
}